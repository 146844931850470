import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PrivacyView from '../views/PrivacyView.vue'
import TermView from '../views/TermView.vue'
// import ShamalView from "@/views/ShamalView.vue";
import RedirectView from "@/views/RedirectView.vue";
import AppViewVue from '@/views/AppView.vue';
import SupportView from "@/views/SupportView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView
  },
  {
    path: '/term',
    name: 'term',
    component: TermView
  },
  {
    path: '/shamal',
    name: 'shamal',
    component: RedirectView
  },
  {
    path: '/application',
    name: 'application',
    component: AppViewVue
  },
  {
    path: '/support',
    name: 'application',
    component: SupportView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
