<template>
  <div class="home" style="padding: 2em">
    <img alt="Vue logo" src="../assets/logo.png" width="200">
    <h1>Сайт скоро будет доступен!</h1>
  </div>
</template>

<script>

export default {
  name: 'HomeView'
}
</script>

<style scoped>
body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding:1em; }
</style>
