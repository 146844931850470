<template>
  <div class="app-advanced p-10">
    <img class="mx-auto" alt="Vue logo" src="../assets/logo.png"/>

    <form
        @submit="handleSubmit"
        class="w-1/2 mx-auto mt-5"
    >
      <div class="my-2">
        <input
            type="text"
            v-model="name"
            placeholder="Ваше имя"
            name="name"
            class="p-3 shadow outline-none w-full"
            required
        />
      </div>

      <div class="my-2">
        <textarea
            rows="7"
            v-model="message"
            placeholder="Сообщение"
            name="message"
            class="p-3 shadow outline-none w-full"
            required
        />
      </div>

      <div class="my-2">
        <button type="submit">
          ОТПРАВИТЬ
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "SupportView",
  data() {
    return {
      name: "",      // Added 'name' data property
      message: "",   // Added 'message' data property
    };
  },
  methods: {
    async handleSubmit(event) {
      event.preventDefault();
      try {
        const response = await fetch("https://api.vqnetwork.kz/v1/feedback", {
          method: 'post',
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: this.name,
            message: this.message,
          }),
        });

        if (response.ok) {
          alert('Спасибо. Ваша заявка отправлена!');
        } else {
          alert('Произошла ошибка! Попробуйте позже!.');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('Произошла ошибка! Попробуйте позже!.');
      }
    },
  },
};

</script>

<style scoped>
body { font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; padding:1em; }
input, textarea {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
img {
  width: 150px;
  margin: 3rem 0 1rem;
}
.w-1\/2 {
  width: 50%;
}
.mt-5 {
  margin-top: 1.25rem;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.shadow {
  border: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),0 1px 8px 0 rgba(0, 0, 0, 0.1);
}
.p-3 {
  padding: 0.75rem;
}
.w-full {
  width: 100%;
}
.my-2 {
  margin: 0.5rem 0;
}
button {
  border: 0;
  padding: 1rem 3.5rem;
  background: #2e3190;
  color: white;
  cursor: pointer;
}
</style>
