<template>
  <div class="container">
    <div class="center_block">
      <h1>VQ BusinessClub</h1>
      <h3>Переходите на наш Instagram</h3>
      <a href="https://instagram.com/vq.businessclub?igshid=NTc4MTIwNjQ2YQ==k"></a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RedirectView'
}
</script>

<style scoped>
</style>
