<template>
  <div class="container">
    <div class="center_block">
      <h1>VQ BusinessClub</h1>
      <div style="margin: 2em 0">
        <img alt="Vue logo" src="../assets/logo.png" style="width: 150px">
      </div>
      <p>
        Проект VQ был создан в г. Алматы, Казахстан. Существует с 2017 года, на данный момент объединяет более двухсот
        основателей бизнеса в закрытом бизнес-клубе.
      </p>
      <h3>Скачайте приложение прямо сейчас!</h3>
      <a href="https://testflight.apple.com/join/pvqgbzhk" class="app_store">
        <img src="../assets/app_store.svg" alt="App Store" width="100%">
      </a>
      <a href="https://play.google.com/store/apps/details?id=kz.vqnetwork.mobile" class="google_play">
        <img src="../assets/google_play.svg" alt="Google Play" width="100%">
      </a>
      <p style="font-size: 13px">
        Если у вас есть вопросы или требуется помощь, пожалуйста, свяжитесь с нашей командой поддержки по адресу
        <a href="">
          support@myvq.kz.
        </a>
        Мы всегда готовы помочь вам.
      </p>
    </div>
  </div>
</template>
<style scoped>
.center_block {
  margin: 3em auto;
  max-width: 720px;
  text-align: center;
}
.app_store {
  display: block;
  width: 200px;
  margin: 0 auto
}
.google_play {
  display: block;
  width: 225px;
  margin: 0 auto
}
</style>
